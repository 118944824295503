import React, { useEffect, useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import darkInstagram from '../assets/dark-instagram.png'
import lightInstagram from '../assets/light-instagram.png'
import darkFacebook from '../assets/dark-facebook.png'
import lightFacebook from '../assets/light-facebook.png'
import AndriodBage from '../assets/google-play-badge.png'
import IosBage from '../assets/iosBage.png'
import phoneLogo from '../assets/logo192.png'
import phoneMockup from '../assets/phone-up.png';
import HomeDrawer2 from '../Components/HomeDrawer2';
import couple100 from '../assets/couple100.png'
import couple200 from '../assets/couple200.png'
import couple300 from '../assets/couple300.png'






const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [themeSecondColor, setSecondColor] = useState(null);
  const [themeInstagramImage, setInstagramImage] = useState('');
  const [themeFacebookImage, setFacebookImage] = useState('');
  const [themeTextColor, setTheme] = useState(null);
  const [themeBackgroundColor, setThemeBackgroundColor] = useState(null);

  const isDark = undefined;
  // Detecting the default theme
  const minMax = useMediaQuery({ query: '(max-width: 900px)' })

  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      document.body.classList.add("dark");
      setTheme('#fff')
      setSecondColor('#b9bfc8')
      setInstagramImage(darkInstagram)
      setFacebookImage(darkFacebook)
      setThemeBackgroundColor('#000')
    } else {
      document.body.classList.remove("dark");
      setTheme('#000')
      setSecondColor('#515965')
      setInstagramImage(lightInstagram)
      setFacebookImage(lightFacebook)
      setThemeBackgroundColor('#fff')
    }
  }, [value]);

  function getMobileOperatingSystem() {

    // Get the user agent string from the browser
    const userAgent = navigator.userAgent;

    // Check if the user agent string contains keywords for different operating systems
    if (userAgent.match(/Windows/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      window.open('https://apps.apple.com/us/app/pine-dating-for-the-caribbean/id1665001650', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Android/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/iOS/i)) {
      window.open('https://apps.apple.com/us/app/pine-dating-for-the-caribbean/id1665001650', "_blank", "noreferrer");
      return null;
    } else {
      return null
    }
  }

  return (
    // <div style={sectionStyle} >
    <div style={{ backgroundColor: themeBackgroundColor, }}>
      {/* <div class="covername"  >
        <div className="cover-color">



          <div className="home-drawer">
            <HomeDrawer2 />
          </div>

          <div className="top-link-title">

            <Link style={{ textDecoration: 'none', margin: '1%', color: '#fff', fontSize: 16 }} to="/about-us">About</Link>

            <Link style={{ textDecoration: 'none', margin: '1%', color: '#fff', fontSize: 16 }} to="/contact-us">Help</Link>

            <Link style={{ textDecoration: 'none', margin: '1%', color: '#fff', fontSize: 16 }} to="/dating-tips">Dating Tips</Link>

            <Link style={{ textDecoration: 'none', margin: '1%', color: '#fff', fontSize: 16 }} to="/safety-tips">Safety Tips</Link>

          </div>

          {minMax ? <div className="mobile-logo">
            <img src={MobileLogo} alt="Trulli" width="110" height="110"></img>
          </div> : null}

          {minMax ? <div className="mobile-logo-name">
            <img src={NameMobileLogo} alt="Trulli" width="250" height="100"></img>
          </div> : null}

          {!minMax ? <div className="Home-logo-style">
            <img src={NameLogo} alt="Trulli" className='name-logo-style'></img>
          </div> : null}

          {minMax ? <div className="home-title" >
            <p className="home-title-mobile" >For Every Single</p>
            <p className="home-title-mobile" >Guyanese</p>
          </div> : <div className="home-title" ><p className="home-title-web" >For Every Single Guyanese</p></div>}

          <div className='home-text1' >

            {minMax ? null : <p className="home-sub-title-web"  >Get The App Today! </p>}

          </div>

          <div className="download-now-button" onClick={getMobileOperatingSystem} >
            {minMax ? <div style={{ backgroundColor: '#fff', borderRadius: 50, width: '80%', display: 'flex', justifyContent: 'center', }}>
              <p className="download-now-mobile" >Download Yana</p> </div> :
              <div style={{ backgroundColor: '#fff', borderRadius: 50, width: '30%', display: 'flex', justifyContent: 'center', }}>
                <p className="download-now-web" >Download Yana</p> </div>}
          </div>

        </div>

      </div> */}

      <div style={{ position: 'absolute', top: 20, left: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <div><img src={phoneLogo} alt="Trulli" width="30" height="30" /></div>
          <div><p className="home-logo" >Caribe Match</p></div>
        </div>
      </div>

      <div className="home-drawer">
        <HomeDrawer2 />
      </div>

      <div className="top-link-title">

        <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, fontSize: 16 }} onClick={() => window.location.href = 'https://yana-gy.com/'}>About</Link>

        <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, fontSize: 16 }} to="/contact-us">Help</Link>

        <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, fontSize: 16 }} to="/dating-tips">Dating Tips</Link>

        <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, fontSize: 16 }} to="/safety-tips">Safety Tips</Link>

      </div>

      {minMax ? <div >

        <div style={{}}>

          <div style={{ marginTop: '35%', marginRight: '5%', marginLeft: '5%' }} >
            <p className="main-pargrah-mobile-font" >For Every Single West Indian.</p>
          </div>

          <div className='pargrah-mobile-font' style={{ marginTop: '1%', marginRight: '5%', marginLeft: '5%' }} >
            <p className='pargrah-mobile-font' style={{ color: themeTextColor }}>  Caribe Match connects you with
              locals across our beautiful Caribbean nations!</p>
            <p className='pargrah-mobile-font' style={{ color: themeTextColor }}>Find meaningful connections Today.</p>
          </div>


          <div style={{ marginTop: '1%', marginRight: '5%', marginLeft: '5%', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={getMobileOperatingSystem} >
            <h4 className='pargrah-font-Download'>
              Download Now
            </h4>
            <div style={{ alignSelf: 'center', marginLeft: 20 }}>
              <FontAwesomeIcon icon={faArrowRightLong} color='#229dfc' />
            </div>
          </div>

 <div style={{ position: 'relative', top: '15%',marginLeft: '5%' }}>
          {/* First Image */}
          <div style={{ zIndex: 10,  }}>
            <img src={couple300} alt="Couple 300" style={{ position: 'relative', top: '20%' }} className='phone-up-image-mobile' />
          </div>

          {/* Second Image - Halfway on top of the first image */}
          <div style={{ position: 'absolute', top: '30%', left: '50%', zIndex: 1 }}>
            <img src={couple200} alt="Couple 300" className='phone-up-image-mobile1' />
          </div>
        </div>
          
          {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '18%', }} >
            <div style={{ width: '90%', }}>
              <img src={couple300} alt="Trulli" className='phone-up-image-mobile' style={{}}></img>
            </div>
          </div> */}

        </div>

      </div> : <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '100px', alignItems: 'center', marginTop: 20, marginLeft: 150, marginRight: 150 }}>

        <div style={{}}>
          <div style={{ marginTop: '12%' }} >
            <h1 className="main-pargrah-font" >For Every Single West Indian.</h1>
          </div>

          <div style={{ marginTop: '5%' }} >
            <p className='pargrah-font' style={{ color: themeTextColor }}>  Caribe Match connects you with
              locals across our beautiful Caribbean nations!</p>
            <p className='pargrah-font' style={{ color: themeTextColor }}>Find meaningful connections Today.</p>
          </div>

          <div style={{ marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={getMobileOperatingSystem} >
            <h4 className='pargrah-font-Download'>
              Download Now
            </h4>
            <div style={{ alignSelf: 'center', marginLeft: 20 }}>
              <FontAwesomeIcon icon={faArrowRightLong} color='#229dfc' />
            </div>
          </div>

        </div>

        <div style={{ position: 'relative', top: '15%', width: 'max-content' }}>
          {/* First Image */}
          <div style={{ zIndex: 1 }}>
            <img src={couple100} alt="Couple 100" style={{ position: 'relative' }} className='phone-up-image1' />
          </div>

          {/* Second Image - Halfway on top of the first image */}
          <div style={{ position: 'absolute', top: '20%', left: '30%', zIndex: 1 }}>
            <img src={couple300} alt="Couple 300" className='phone-up-image' />
          </div>

          {/* Third Image - Halfway at the bottom of the second image */}
          <div style={{ position: 'absolute', top: '10%', left: '60%', zIndex: 2 }}>
            <img src={couple200} alt="Couple 200" className='phone-up-image' />
          </div>
        </div>
      </div>}

      <div className="footer-color">

        <div className='home-android-ios' >
          <div onClick={getMobileOperatingSystem}>

            {minMax ? <img src={AndriodBage} alt="Trulli" width="145" height="65"></img> : <img src={AndriodBage} alt="Trulli" width="155" height="75"></img>}

          </div>

          {minMax ? <div onClick={getMobileOperatingSystem} style={{ marginTop: 12 }}> <img src={IosBage} alt="Trulli" width="130" height="41"></img> </div> : <div onClick={getMobileOperatingSystem} style={{ marginTop: 12 }}><img src={IosBage} alt="Trulli" width="150" height="50"></img> </div>}

        </div>

        {/* <div className='social-icons' style={{ marginTop: '3%' }} >
          <div style={{ marginRight: 10 }}>
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </div>

          <div style={{ marginLeft: 10 }}>
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </div>
        </div> */}

        <div className='footer' style={{ marginTop: '3%' }}>


          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://yana-gy.com/'}>About us</Link>
          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://yana-gy.com/'}> | </Link>


          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/dating-tips">Dating  Tips</Link>
          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/dating-tips"> | </Link>

          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/safety-tips">Dating  Safety  Tips</Link>
          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/safety-tips"> | </Link>

          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/privacy-policy">Privacy  policy</Link>
          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/privacy-policy"> | </Link>

          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/terms-conditions">Terms  &  Conditions</Link>
          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/terms-conditions"> | </Link>

          <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} to="/contact-us"> Support</Link>

        </div>

        <div className='copy-right'>
          <p className='pargrah-font' style={{ color: themeTextColor }}>@2024 Yana Plus LLC.</p>
        </div>

      </div>
    </div>
  );
}

export default Home;


// import React, { useState, useEffect } from 'react';
// import { googleLogout, useGoogleLogin } from '@react-oauth/google';
// import axios from 'axios';

// function Home() {
//     const [ user, setUser ] = useState([]);
//     const [ profile, setProfile ] = useState([]);

//     const login = useGoogleLogin({
//         onSuccess: (codeResponse) => setUser(codeResponse),
//         onError: (error) => console.log('Login Failed:', error)
//     });

//     useEffect(
//         () => {
//             if (user) {
//                 axios
//                     .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
//                         headers: {
//                             Authorization: `Bearer ${user.access_token}`,
//                             Accept: 'application/json'
//                         }
//                     })
//                     .then((res) => {
//                         setProfile(res.data);
//                     })
//                     .catch((err) => console.log(err));
//             }
//         },
//         [ user ]
//     );

//     // log out function to log the user out of google and set the profile array to null
//     const logOut = () => {
//         googleLogout();
//         setProfile(null);
//     };

//     return (
//         <div>
//             <h2>React Google Login</h2>
//             <br />
//             <br />
//             {profile ? (
//                 <div>
//                     <img src={profile.picture} alt="user image" />
//                     <h3>User Logged in</h3>
//                     <p>Name: {profile.name}</p>
//                     <p>Email Address: {profile.email}</p>
//                     <br />
//                     <br />
//                     <button onClick={logOut}>Log out</button>
//                 </div>
//             ) : (
//                 <button onClick={() => login()}>Sign in with Google 🚀 </button>
//             )}
//         </div>
//     );
// }
// export default Home;

//import { GoogleOAuthProvider } from '@react-oauth/google';
// <GoogleOAuthProvider clientId="261929303879-uv8lcikmcepka7o1uuktab03v0r1n5f9.apps.googleusercontent.com">
// <React.StrictMode>
//   <App />
// </React.StrictMode>
// </GoogleOAuthProvider>